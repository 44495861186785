<template>
  <div></div>
</template>

<script>

import {ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {useStore} from 'vuex';
import {ACT_BRIDGE_SSO_AUTH, ACT_GET_SHORT_URL, TOKEN_KEY, ACT_UPDATE_SHORT_URL} from '@/store/modules/auth/auth';
import {getItem, isSuccess, lengthCheck, timestampToDateFormat} from '@/assets/js/util';
import {saveToken} from '@/assets/js/localstorage.service';
import {useAlert} from '@/assets/js/modules/common/alert';

export default {
  name: "ModalSSO",
  components: {},
  setup() {
    const store = useStore(),
          route = useRoute(),
          router = useRouter();
    
    const aMsg = ref('잘못된 접근입니다.');
    const auth = ref({});
    const currentTime = ref(new Date());
    const {showMessage} = useAlert();
    
    const noSession = () => { showMessage(aMsg.value); }
    
    const getSSO = (sUrl) => {
      store.dispatch(`auth/${ACT_GET_SHORT_URL}`, sUrl).then(res => {
        if (getItem(res)) {
          auth.value = getItem(res);
          if (auth.value.expreDt <= currentTime.value.getTime()) {
            showMessage(`기한만료(${timestampToDateFormat(auth.value.expreDt, 'yyyy.MM.dd hh:mm')} 까지) 페이지 입니다.<br>관리자에게 문의 바랍니다.`);
          } else if (auth.value.expreCnt > 0 && auth.value.cntnCnt >= auth.value.expreCnt) {
            showMessage(`횟수제한(${auth.value.expreCnt}회) 페이지 입니다.<br>관리자에게 문의 바랍니다.`);
          } else {
            initBridgeSSO();
          }
        } else {
          noSession();
        }
      }).catch(e => {
        console.error(e);
        noSession();
      });
      
    }
    
    const initBridgeSSO = async() => {
      await store.dispatch(`auth/${ACT_BRIDGE_SSO_AUTH}`, auth.value.inptCn01).then(res => {
        if(isSuccess(res) && lengthCheck(res) && res.authToken) {
          saveToken(TOKEN_KEY, res.authToken);
          router.push({name: auth.value.inptNm01, params: JSON.parse(auth.value.urlCn)});
        } else {
          noSession();
        }
      }).catch(e => {
        console.log(e);
        noSession();
      });
      
      await store.dispatch(`auth/${ACT_UPDATE_SHORT_URL}`, auth.value.shortUrlSn).then(() => {
      }).catch(e => {
        console.log(e);
        noSession();
      });
    }
    
    if (route.params.sUrl) {
      getSSO(route.params.sUrl);
    }

    return {
    }
  }
}
</script>


